import { Controller } from '@hotwired/stimulus'
import { transition } from '../transition.js'

export default class extends Controller {
  static targets = ['button', 'menu', 'option']

  toggle() {
    this.menuTarget.classList.toggle('hidden')
  }

  updateValue() {
    var newValue = []
    this.optionTargets.forEach(target => {
      if (target.checked) {
        newValue.push(target.dataset.name)
      }
    })
    this.buttonTarget.innerHTML = newValue.join(', ')
  }

  hide(event) {
    // if the event is a click and the target is not inside the dropdown, then close it
    if (
      event.target.nodeType &&
      this.element.contains(event.target) === false &&
      !this.menuTarget.classList.contains('hidden')
    ) {
      this.menuTarget.classList.toggle('hidden')
    }

    // if the event is a keydown and the key is escape, then close it
    if (event.key === 'Escape' && !this.menuTarget.classList.contains('hidden')) {
      this.menuTarget.classList.toggle('hidden')
    }
  }
}