import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static values = {
    open: { type: Boolean, default: false },
    target: String,
    class: { type: String, default: 'hidden' }
  }

  toggle() {
    document.querySelectorAll(this.targetValue).forEach(target => {
      target.classList.toggle(this.classValue)
    })
  }
}